var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',_vm._b({attrs:{"width":"500"},model:{value:(_vm.mostra),callback:function ($$v) {_vm.mostra=$$v},expression:"mostra"}},'v-dialog',_vm.$attrs,false),[_c('v-card',[_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.aplicarFiltro.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('SeletorDeCliente',{attrs:{"hideCreate":"","prepend-icon":"mdi-account-group","label":"Cliente / CPF","loading":_vm.buscando,"disabled":_vm.buscando,"returnObject":false,"podeDigitarCpfOuCnpj":""},model:{value:(_vm.filtro.cliente),callback:function ($$v) {_vm.$set(_vm.filtro, "cliente", $$v)},expression:"filtro.cliente"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"multiple":"","label":"Situação do Pedido","items":[
								'Despachado',
								'Ausente',
								'Devolvido',
								'Entregue',
								'Em Ocorrência',
								'Pendente',
								'Faturado',
								'Cancelado',
								'Aguardando Coleta',
								'Em Separação',
								'Pedido pronto para Retirada na Loja'
							]},model:{value:(_vm.filtro.situacoes),callback:function ($$v) {_vm.$set(_vm.filtro, "situacoes", $$v)},expression:"filtro.situacoes"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Identificador"},model:{value:(_vm.filtro.identificador),callback:function ($$v) {_vm.$set(_vm.filtro, "identificador", $$v)},expression:"filtro.identificador"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Identificador Externo"},model:{value:(_vm.filtro.identificadorExterno),callback:function ($$v) {_vm.$set(_vm.filtro, "identificadorExterno", $$v)},expression:"filtro.identificadorExterno"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-checkbox',{attrs:{"label":"Pedidos com frete"},model:{value:(_vm.filtro.possuiFrete),callback:function ($$v) {_vm.$set(_vm.filtro, "possuiFrete", $$v)},expression:"filtro.possuiFrete"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"color":"primary","filled":""},on:{"click":_vm.aplicarFiltro}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Aplicar ")],1),_c('v-btn',{attrs:{"color":"grey darken-2","text":"","type":"button","disabled":_vm.buscando},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.limparFiltros.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-broom ")]),_vm._v(" Limpar ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }